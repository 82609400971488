export const activitiesdata = [
    {
        id: 1,
        CoverImg: '/assets/edu_img.jpg',
        text: "Education"
    },
    {
        id: 2,
        CoverImg: "/assets/health_img.jpg",
        text: "Health Aid"
    },
    {
        id: 3,
        CoverImg: '/assets/poverty_image.jpg',
        text: "Poverty Relief"
    },
    {
        id: 4,
        CoverImg: '/assets/child_img.jpg',
        text: "Child Welfare"
    },
    {
        id: 5,
        CoverImg: '/assets/humanright_img.jpg',

        text: "Human Rights"
    },
    {
        id: 6,
        CoverImg: '/assets/dista_img.jpg',
        text: "Disaster Relief"
    },
];export default activitiesdata;



export const trendingdata = [
    {
        id: 1,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
     {
        id: 2,
        CoverImg: "/assets/image6.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
      {
        id: 2,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
]

export const explorecategorylist = [
    {
        id: 1,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 2,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 3,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 4,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
     {
        id: 5,
        CoverImg: "/assets/image6.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
      {
        id: 6,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 7,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 8,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 9,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
]
export const discoverpagesdata = [
    {
        id: 1,
        CoverImg: '/assets/edu_img.jpg',
        text: "Education"
    },
    {
        id: 2,
        CoverImg: "/assets/health_img.jpg",
        text: "Health Aid"
    },
    {
        id: 3,
        CoverImg: '/assets/poverty_image.jpg',
        text: "Poverty Relief"
    },
    {
        id: 4,
        CoverImg: '/assets/child_img.jpg',
        text: "Child Welfare"
    },
    {
        id: 5,
        CoverImg: '/assets/humanright_img.jpg',

        text: "Human Rights"
    },
    {
        id: 6,
        CoverImg: '/assets/dista_img.jpg',
        text: "Disaster Relief"
    },
    {
        id: 7,
        CoverImg: '/assets/dista_img.jpg',
        text: "Faith"
    },
    {
        id: 8,
        CoverImg: '/assets/dista_img.jpg',
        text: "Animal"
    },
    {
        id: 9,
        CoverImg: '/assets/dista_img.jpg',
        text: "Sport"
    },
];

export const testimionalData = [
    {
        id: 1,
        text: "Grateful for the website's support that funded my medical treatment, restoring both my health and faith in humanity.",
        CoverImg: "/assets/img1",
        authorName: "Jennifer Nwachukwu",
        authorRole:"Community Health Advocate"
    },
     {
        id: 2,
        text: "Thanks to the website, my dream of education is now a reality. Generous  donors have anchored my aspirations.",
        CoverImg: "/assets/img2",
        authorName: "Bayo Mohammed",
        authorRole:"Student Scholar"
    },
      {
        id: 3,
        text: "Grateful for the website's support that funded my medical treatment, restoring both my health and faith in humanity.",
        CoverImg: "/assets/img3",
        authorName: "Jennifer Nwachukwu",
        authorRole:"Community Health Advocate"
    },
       {
        id: 4,
        text: "Thanks to the website, my dream of education is now a reality. Generous  donors have anchored my aspirations.",
        CoverImg: "/assets/img4.jpg",
        authorName: "Adeola Favor",
        authorRole:"Community Health Advocate"
    },
]

export const categories = [
  {
    id: 1,
    name: "Trending",
    categorylist : [
  {
        id: 1,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 2,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 3,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 4,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
     {
        id: 5,
        CoverImg: "/assets/image6.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
      {
        id: 6,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 7,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 8,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 9,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
]

  },
  {
    id: 2,
    name: "Ending soon",
     categorylist : [
  {
        id: 1,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 2,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 3,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 4,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
     {
        id: 5,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
      {
        id: 6,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 7,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 8,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 9,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
]

  },
  {
    id: 3,
    name: "Newest",
     categorylist : [
  {
        id: 1,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 2,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 3,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 4,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
     {
        id: 5,
        CoverImg: "/assets/image6.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
      {
        id: 6,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 7,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 8,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 9,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
]

  },
  {
    id: 4,
    name: "Most Founded",
    categorylist : [
   {
        id: 1,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 2,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 3,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 4,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
     {
        id: 5,
        CoverImg: "/assets/image6.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
      {
        id: 6,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 7,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 8,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 9,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
]

    },
   {
    id: 5,
    name: "Least Founded",
    categorylist : [
   {
        id: 1,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 2,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 3,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 4,
        CoverImg: "/assets/image1.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
     {
        id: 5,
        CoverImg: "/assets/image6.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
      {
        id: 6,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 7,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 8,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
    {
        id: 9,
        CoverImg: "/assets/image2.jpg",
        title: "Support for Helmes Orphanage, East Legon...",
        content: "Lorem ipsum dolor sit amet consectetur. Pharetra mi purus mi scelerisque habitasse pulvinar. Velitt amet consectet......",
        targetvalue: "$ 1000",
        currentvalue: "$ 50.00",
        donatingImg: "/assets/image3.jpg",
        donatingImg2: "/assets/image4.jpg",
        donatingImg3: "/assets/image5.jpg",
        totalDonation: "+26 People are donating"
    },
]

  }
];

export const events = [
    {
        id: 1,
        calendarIcon: "/assets/svg/calendar.svg",
        date: "20/12/2023",
        googleIcon: "/assets/svg/Social icon.svg",
        desc: "Google Meet",
        user: "/assets/img1",
        userName:"Iman Akbar"

    },
     {
        id: 2,
        calendarIcon: "/assets/svg/calendar.svg",
        date: "20/12/2023",
        googleIcon: "/assets/svg/Social icon.svg",
        desc: "Google Meet",
        user: "/assets/img1",
        userName:"Iman Akbar"

    },
      {
        id: 3,
        calendarIcon: "/assets/svg/calendar.svg",
        date: "20/12/2023",
        googleIcon: "/assets/svg/Social icon.svg",
        desc: "Google Meet",
        user: "/assets/img1",
        userName:"Iman Akbar"

    },
       {
        id: 4,
        calendarIcon: "/assets/svg/calendar.svg",
        date: "20/12/2023",
        googleIcon: "/assets/svg/Social icon.svg",
        desc: "Google Meet",
        user: "/assets/img1",
        userName:"Iman Akbar"

    },
        {
        id: 5,
        calendarIcon: "/assets/svg/calendar.svg",
        date: "20/12/2023",
        googleIcon: "/assets/svg/Social icon.svg",
        desc: "Google Meet",
        user: "/assets/img1",
        userName:"Iman Akbar"

    },
         {
        id: 6,
        calendarIcon: "/assets/svg/calendar.svg",
        date: "20/12/2023",
        googleIcon: "/assets/svg/Social icon.svg",
        desc: "Google Meet",
        user: "/assets/img1",
        userName:"Iman Akbar"

    },
]