

const Othercomponent =() => {
    return(
   <div>
    
   </div>
    )
}

export default Othercomponent